import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Routes } from '@angular/router';
import { APPS } from '@config';
import { AuthGuard } from '@features/auth';
import { CpqGuard } from '@sales-libs/shared/util';

const redirectToIndustries = '/industries';

export const routes: Routes = [
  {
    path: '',
    canActivate: [
      (next: ActivatedRouteSnapshot) => inject(AuthGuard).canActivate(next),
    ],
    children: [
      {
        path: '',
        redirectTo: 'projects',
        pathMatch: 'full',
      },
      {
        path: 'projects',
        canActivate: [
          (next: ActivatedRouteSnapshot) => inject(CpqGuard).canActivate(next),
        ],
        loadChildren: () =>
          import('./projects/projects.module').then((m) => m.ProjectsModule),
        data: {
          breadcrumb: 'common.projects',
        },
      },
      {
        path: 'industries',
        canActivate: [
          (next: ActivatedRouteSnapshot) => inject(CpqGuard).canActivate(next),
        ],
        loadChildren: () =>
          import('./industry/industry.module').then((m) => m.IndustryModule),
        data: {
          roles: [APPS.PALDESK_CPQ.ROLES.CPQ_SALES_CONSULTANT],
          breadcrumb: 'common.segments',
        },
      },
      {
        path: 'products',
        canActivate: [
          (next: ActivatedRouteSnapshot) => inject(CpqGuard).canActivate(next),
        ],
        loadChildren: () =>
          import('./products/products.module').then((m) => m.ProductsModule),
        data: {
          roles: [APPS.PALDESK_CPQ.ROLES.CPQ_SALES_CONSULTANT],
        },
      },
      {
        path: 'orders',
        canActivate: [
          (next: ActivatedRouteSnapshot) => inject(CpqGuard).canActivate(next),
        ],
        data: {
          roles: [APPS.PALDESK_CPQ.ROLES.CPQ_ORDER_HISTORY],
          breadcrumb: 'project.orders',
        },
        loadChildren: () =>
          import('./orders/orders.module').then((m) => m.OrdersModule),
      },
      {
        path: 'sandbox',
        canActivate: [
          (next: ActivatedRouteSnapshot) => inject(CpqGuard).canActivate(next),
        ],
        loadChildren: () =>
          import('./sandbox/sandbox.module').then((m) => m.SandboxModule),
        data: {
          roles: [APPS.PALDESK_CPQ.ROLES.CPQ_SALES_CONSULTANT],
        },
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('./settings/settings.module').then((m) => m.SettingsModule),
        canActivate: [
          (next: ActivatedRouteSnapshot) => inject(CpqGuard).canActivate(next),
        ],
        data: {
          breadcrumb: 'breadcrumb.settings',
        },
      },
      {
        path: 'early-access',
        loadChildren: () =>
          import('./early-access/early-access.module').then(
            (m) => m.EarlyAccessModule,
          ),
        canActivate: [
          (next: ActivatedRouteSnapshot) => inject(CpqGuard).canActivate(next),
        ],
        data: {
          breadcrumb: 'breadcrumb.early_access',
        },
      },
      {
        path: 'help',
        loadChildren: () =>
          import('@paldesk/design-system/feature/help-page').then(
            (m) => m.DSHelpPageModule,
          ),
        data: {
          roles: [APPS.PALDESK_CPQ.ROLES.CPQ_SALES_CONSULTANT],
          breadcrumb: 'general.help',
        },
      },
      {
        path: 'pricelist',
        canActivate: [
          (next: ActivatedRouteSnapshot) => inject(CpqGuard).canActivate(next),
        ],
        data: {
          breadcrumb: 'breadcrumb.pricelist',
        },
        loadChildren: () =>
          import('./pricelist/pricelist.module').then((m) => m.PricelistModule),
      },
    ],
  },
  {
    path: 'callback',
    children: [],
  },
  {
    path: 'special',
    loadChildren: () =>
      import('@design-system/feature/special-pages').then(
        (m) => m.DsSpecialPagesModule,
      ),
  },
  {
    path: '**',
    redirectTo: 'special',
  },
];
