import { UserService } from '@features/auth';
import { CPQ_ROLES } from '../roles';
import { FilterableMenuItem } from './model';
import { cpqSalesConsultant } from './shared';

export const lostOrder: FilterableMenuItem = {
  title: 'project.lost_order',
  icon: 'remove_shopping_cart',
  routerLink: '/projects/lost-order',
  filterPredicate: (userService: UserService) =>
    userService.hasRole(CPQ_ROLES.CPQ_USER),
};

export const projectsMenu: FilterableMenuItem = {
  title: 'common.projects',
  icon: 'assignment',
  routerLink: '/projects',
  filterPredicate: (userService: UserService) =>
    !cpqSalesConsultant(userService) &&
    userService?.hasRole(CPQ_ROLES.CPQ_USER),
  children: [lostOrder],
};
