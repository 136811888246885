import { InjectionToken } from '@angular/core';

export interface DsAppWrapperConfig {
  apiBasePath: string;
  paldeskBasePath: string;
  productScreenBasePath: string;
  appInsightsRole?: string;
  paldeskSupportPath?: string;
  paldeskDataProtectionRegulationUrl?: string;
  appGatewayBasePath: string;
  isNativeApp?: boolean;
  appName: string;
  appNewsPath?: string;
  appHelpPath?: string;
  anonymousUserSupportedLanguageCodes?: string[]; //If not provided, a default list of supported languages will be used
}

export const DS_APP_WRAPPER_CONFIG = new InjectionToken<DsAppWrapperConfig>(
  'DS_APP_WRAPPER_CONFIG',
);

export const NEW_ES_BASE_PATH = new InjectionToken<string>('NEW_ES_BASE_PATH');
