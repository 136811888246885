import { ComponentType } from '@angular/cdk/portal';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Optional,
  Output,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { UserService } from '@features/auth';
import { FreshdeskWidgetService } from '@paldesk/shared-lib/features/freshdesk-widget';
import { SurveyDialogService } from '@paldesk/survey';
import { DsAppWrapperSetup } from '../app-wrapper.component';
import { AppsComponent } from '../apps/apps.component';
import { DS_APP_WRAPPER_CONFIG, DsAppWrapperConfig } from '../config';
import { UserComponent } from '../user/user.component';

@Component({
  selector: 'ds-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  @Output() userClick: EventEmitter<{
    component?: ComponentType<any>;
    isMenu?: boolean;
  }> = new EventEmitter<{
    component?: ComponentType<any>;
    isMenu?: boolean;
  }>();

  @Input() helpIconUrl: string;
  @Input() hasNoLogIn: boolean;
  @Input() helpOpenSameWindow: boolean;
  @Input() hasSidebar = true;
  @Input() setup: DsAppWrapperSetup;

  paldeskBasePath = 'https://paldesk.palfinger.com';

  constructor(
    public userService: UserService,
    public media: MediaObserver,
    public surveyService: SurveyDialogService,
    @Inject(DS_APP_WRAPPER_CONFIG) private config: DsAppWrapperConfig,
    @Optional() private freshdeskService: FreshdeskWidgetService,
  ) {
    this.freshdeskService.init.subscribe({
      next: () => this.freshdeskService.hideTicketWidgetIconLauncher(),
    });

    if (this.config.paldeskBasePath) {
      this.paldeskBasePath = config.paldeskBasePath;
    }
  }

  openFeedbackDialog() {
    this.surveyService.openQualtricsSurvey(
      SurveyDialogService.paldeskCSISurveyId,
    );
  }

  appsClick() {
    this.userClick.emit({ component: AppsComponent });
  }

  accountClick() {
    this.userClick.emit({ component: UserComponent });
  }

  sidebarClick() {
    this.userClick.emit({
      isMenu: true,
    });
  }

  showFreshdeskWidget() {
    this.freshdeskService.openTheWidget();
  }

  get collapseMenu(): boolean {
    let menuItemsCount = 0;
    if (this.helpIconUrl) menuItemsCount++;
    if (!this.hasNoLogIn) menuItemsCount++;
    return menuItemsCount > 0;
  }
}
